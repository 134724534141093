import { SET_SIDEBAR_SHOW } from './../Types';

const initialState = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false
}

const uIReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SIDEBAR_SHOW:
            return {
                ...state,
                sidebarShow: action.payload
            }
        default:
            return state;
    }
}

export default uIReducer;

