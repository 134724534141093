import { SET_AREA_NAME } from './../Types';


const initialState = {
    areaName: ""
}


const areaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AREA_NAME:
            return {
                ...state,
                areaName: action.payload
            }
        default:
            return state;
    }
}

export default areaReducer;