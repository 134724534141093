import { combineReducers, createStore } from 'redux';
import AreaReducer from './../reducers/areaReducer';
import EventReducer from './../reducers/eventReducer';
import UnionCouncilReducer from './../reducers/unionCouncilReducer';
import UIReducer from '../reducers/uIReducer';

const rootReducer = combineReducers({
  Area: AreaReducer,
  Event: EventReducer,
  UnionCouncil: UnionCouncilReducer,
  Ui: UIReducer
});

const configureStore = () => {
  let store = createStore(rootReducer);
  return { store };
};

export default configureStore;