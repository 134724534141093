import { } from './../Types';


const initialState = {
    unionCouncilName: ""
}

const unionCouncilReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ABC":
            return {
                ...state,
                unionCouncilName: action.payload
            }
        default:
            return state;
    }
}

export default unionCouncilReducer;