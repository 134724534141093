import { } from './../Types';


const initialState = {
    eventName: ""
}

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ABC":
            return {
                ...state,
                eventName: action.payload
            }
        default:
            return state;
    }
}

export default eventReducer;